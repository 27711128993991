.cont {
  width: 1440px;
  margin: 0 auto;
  align-items: center;
}
.span {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #222;
  line-height: 150%;
  font-weight: 700;
  font-size: 2rem;
}

.title {
  font-weight: 600;
  font-size: 2.4rem;
}

.tel {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}
.address {
  font-size: 1.6rem;
}
.info {
  color: #3b4045;
  line-height: 150%;
  font-weight: 400;
  font-size: 1.6rem;
}
.url {
  justify-content: left;
  align-items: center;
  margin-top: 2rem;
  display: flex;
}
.url p {
  font-size: 1.6rem;
  font-weight: 700;
}
