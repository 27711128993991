.main {
  max-width: 1440px;
  height: 100%;
  margin: 0 auto;
  text-align: center;
}

.titlea {
  padding: 5rem;
  text-align: center;
  line-height: 150%;
  font-weight: 700;
  font-size: 4rem;
}

.titlea span {
  color: #d09589;
  font-size: 4rem;
}

.listb {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin: 0 auto;
  margin-bottom: 5rem;
}

.listb li {
  border: 1px solid #d7d9dc;
  padding: 60px 0 60px;
  background-color: #f5f6f8;
  border-radius: 8px;
  font-size: 1.6rem;
}

.listb li span {
  font-size: 1.6rem;
}

.listb li:hover {
  cursor: pointer;
  border-color: #d09589;
}

.search {
  max-width: 1440px;
  height: 100%;
  margin: 0 auto;
  text-align: center;
}
.box {
  margin: 4rem auto;
  position: relative;
  width: 45rem;
}
.box input {
  height: 4.8rem;
  width: 100%;
  border: 1px solid #d7d9dc;
  border-radius: 0.8rem;
  padding: 1rem;
  line-height: 150%;
  font-weight: 500;
  font-size: 1.6rem;
  box-sizing: border-box;
}
.box span {
  position: absolute;
  right: 0;
  padding: 1.3rem;
}

.lista {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 3rem;
}

.select {
  padding: 0.5rem 1rem;
  background-color: #fafafa;
  border-radius: 0.8rem;
  border: 1px solid #d7d9dc;
  border-color: #d09589;
}

.no {
  padding: 0.5rem 1rem;
  background-color: #fafafa;
  border-radius: 0.8rem;
  border: 1px solid #d7d9dc;
}

.lista li span {
  font-size: 1.6rem;
}

.lista li:hover {
  cursor: pointer;
}

.result {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  justify-content: center;
}

.result li {
  padding: 1.6rem;
  background-color: #fafafa;
  border-radius: 0.8rem;
  border: 1px solid #d7d9dc;
}

.result li:hover {
  border-color: #d09589;
}

.url {
  line-height: 150%;
  font-weight: 600;
  font-size: 2rem;
}

.info {
  border-bottom: 1px solid #d7d9dc;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  text-align: left;
}

.title {
  line-height: 150%;
  font-weight: 600;
  font-size: 2rem;
}

.address {
  font-size: 1.4rem;
}

.docs {
  color: #979da7;
  line-height: 2rem;
  text-align: left;
}

.docs p {
  font-size: 1.4rem;
}

/* media */
@media all and (max-width: 1440px) {
  .lista {
    padding: 1rem;
  }
  .list {
    width: 140rem;
  }
}

@media all and (min-width: 768px) and (max-width: 1024px) {
  #content {
    max-width: 1024px;
  }
  .list-content.show .lista li {
    padding: 1.6rem;
    background-color: #fafafa;
    border-radius: 0.8rem;
    border: 1px solid #d7d9dc;
    font-size: 1.6rem;
  }

  #search-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 1rem;
  }
  .list {
    width: 100rem;
  }
  #header #nav {
    width: 96rem;
  }

  #footer .footer-container {
    width: 96rem;
  }
}

@media all and (min-width: 425px) and (max-width: 768px) {
  .lista {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    justify-content: center;
  }

  .list {
    width: 75rem;
  }

  #header #nav {
    width: 72rem;
  }
}

@media all and (max-width: 425px) {
  #title {
    padding: 5rem;
    text-align: center;
    line-height: 150%;
    font-weight: 700;
    font-size: 3rem;
  }
  .list {
    width: 36rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    margin: 0 auto;
    margin-bottom: 5rem;
  }
  .lista {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    justify-content: center;
  }

  #header #nav {
    width: 40rem;
  }

  #search-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 1rem;
  }

  #search-box {
    margin: 4rem auto;
    position: relative;
    width: 35rem;
  }
  #search-box input {
    height: 4.8rem;
    width: 100%;
    border: 1px solid #d7d9dc;
    border-radius: 0.8rem;
    padding: 1rem;
    line-height: 150%;
    font-weight: 500;
    font-size: 1.6rem;
    box-sizing: border-box;
  }

  #search-box span {
    position: absolute;
    right: 0;
    padding: 1.3rem;
  }

  #nav .logo {
    margin: 0 auto;
  }
  #nav .login {
    display: none;
  }
}
